import Header from "./components/Header/Header";
import * as c from "./state/variables";
import './App.css';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";
import Registration from "./components/Registration/Registration";
import HomePage from "./components/HomePage/HomePage";
import HomePageContactUs from "./components/HomePage/HomePageContactUs";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsOfServices from "./components/TermsOfServices/TermsOfServices";
import Payment from "./components/Payment/Payment";
import ThankyouPage from "./components/ThankyouPage/ThankyouPage";
import {questions} from "./components/Registration/RegistrationForm/CarouselForm/carouselState";
import Question from "./components/Registration/RegistrationForm/CarouselForm/Question/Question";
import EmailConfirmationPage from "./components/EmailConfirmationPage/EmailConfirmationPage";




const App = (props) => {
	const pages = []
	if(c.REACT_APP_REGISTRATION_FORM_TEMPLATES !== undefined){
		c.REACT_APP_REGISTRATION_FORM_TEMPLATES.split(";").map((page, i) =>{
			let page_url = "/" + page.split(':')[0];
			let page_template = page.split(':')[1]
			pages.push(<Route path={page_url}><Registration state={props.state} template={page_template}/></Route>)
			console.log("PAGE: " + page_url + "; TEMPLATE: " + page_template)
		})
	}else if(c.REGISTRATION_FORM_TEMPLATE !== undefined){
		pages.push(<Route path="/registration"><Registration template={c.REGISTRATION_FORM_TEMPLATE} state={props.state}/></Route>)
	}else{
		pages.push(<Route path="/registration"><Registration template='SIMPLE_FORM' state={props.state}/></Route>)
	}
	const homePage = []
	console.log(c.HOMEPAGE_TEMPLATE)
	if(c.HOMEPAGE_TEMPLATE === 'CONTACT_US'){
		homePage.push(<Route path="/"><HomePageContactUs/></Route>)
	}else{
		homePage.push(<Route path="/"><HomePage /></Route>)
	}

	return (
		<Router>
			<Header/>
			<div>
				<Switch>
					{pages}
					{/*<Route path="/registration"><Registration state={props.state}/></Route>*/}
					{/*<Route path="/registration-yandex"><Registration state={props.state}/></Route>*/}
					<Route path="/terms-of-service"><TermsOfServices /></Route>
					<Route path="/payment"><Payment state={props.state}/></Route>
					<Route path="/privacy-policy"><PrivacyPolicy state={props.state}/></Route>
					<Route path="/thanks"><ThankyouPage /></Route>
					<Route path="/thankyoupage"><ThankyouPage /></Route>
					<Route path="/thankyoupage_plus"><ThankyouPage /></Route>
					<Route path="/email_confirmation"><EmailConfirmationPage /></Route>
					{homePage}
				</Switch>
			</div>
		</Router>
	)
}

export default App;
