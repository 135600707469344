// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContactUsSmallForm_contactUs_wrapper__Gbivy{\n    margin: auto;\n}\n\n.ContactUsSmallForm_contactUsSmallForm__Rjl98{\n    text-align: center;\n    background-color: #03b2cb;\n    padding: 4rem 0;\n    margin: auto;\n}\n.ContactUsSmallForm_smallForm__2r3rf{\n    max-width: 1024px;\n    margin: auto;\n}\n.ContactUsSmallForm_smallForm__2r3rf input{\n    padding: 1rem;\n    margin-right: .5rem;\n    border-radius: .25rem;\n    width: 20%;\n}\n.ContactUsSmallForm_smallForm__2r3rf input[type=\"submit\"]{\n    background-color: #2A8436;\n}\n@media screen and (max-width: 680px) {\n    .ContactUsSmallForm_contactUsSmallForm__Rjl98{\n        padding: 1rem 0;\n    }\n    .ContactUsSmallForm_smallForm__2r3rf input{\n        padding: .5rem;\n        width: 80%;\n        margin-bottom: .5rem;\n    }\n}\n", "",{"version":3,"sources":["webpack://src/components/HomePage/ContactUsSmallForm/ContactUsSmallForm.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,YAAY;AAChB;AACA;IACI,iBAAiB;IACjB,YAAY;AAChB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,UAAU;AACd;AACA;IACI,yBAAyB;AAC7B;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,cAAc;QACd,UAAU;QACV,oBAAoB;IACxB;AACJ","sourcesContent":[".contactUs_wrapper{\n    margin: auto;\n}\n\n.contactUsSmallForm{\n    text-align: center;\n    background-color: #03b2cb;\n    padding: 4rem 0;\n    margin: auto;\n}\n.smallForm{\n    max-width: 1024px;\n    margin: auto;\n}\n.smallForm input{\n    padding: 1rem;\n    margin-right: .5rem;\n    border-radius: .25rem;\n    width: 20%;\n}\n.smallForm input[type=\"submit\"]{\n    background-color: #2A8436;\n}\n@media screen and (max-width: 680px) {\n    .contactUsSmallForm{\n        padding: 1rem 0;\n    }\n    .smallForm input{\n        padding: .5rem;\n        width: 80%;\n        margin-bottom: .5rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactUs_wrapper": "ContactUsSmallForm_contactUs_wrapper__Gbivy",
	"contactUsSmallForm": "ContactUsSmallForm_contactUsSmallForm__Rjl98",
	"smallForm": "ContactUsSmallForm_smallForm__2r3rf"
};
export default ___CSS_LOADER_EXPORT___;
