import Step from "../Step/Step";
import s from './StepsHeader.module.css';
import {STEPS} from "../../../state/variables";


const StepsHeader = (props) => {
    let allSteps = STEPS.map(d=><Step id={d.id} counter={STEPS.length} text={d.text} current={props.currentStep} />);

    return(
        <div>
            <div className="info_heading center w_100">

                <h1 className="immigrate_header">
                    Immigrate to Canada in {STEPS.length} easy steps
                </h1>
            </div>
            <div className={s.info + ' padding_hf center color_white'}>
                <div className="info_text">
                    <p className={s.fill_text}>Fill the details below to make sure you meet the canadian government
                        requirements.</p><p className={s.fill_text_good_luck}><b>Good Luck!</b></p>
                </div>
            </div>
            <div className="w_100 margin_a steps max_width_1024">
                <div id="steps" className="w_75 sp_w_95 margin_a padding_db_v steps_wrapper">
                    {allSteps}
                </div>
            </div>
        </div>
    )
}
export default StepsHeader;
