import * as s from "./ContactUsSmallForm.module.css";

const ContactUsSmallForm = (props) => {

    // constructor(props) {
    //     super(props);
    // }
    console.log(props.position)
    console.log(props.width)
    return (
        <div style={{maxWidth: props.width}} className={s.contactUs_wrapper} >
            <div className={s.contactUsSmallForm}>
                <h2>The freedom to create, evolve and make new friends starts here!</h2>
                <div className={s.smallForm}>
                    <input type="text" placeholder='First and Last Name'/>
                    <input type="email" placeholder='Email'/>
                    <input type="phone" placeholder='Phone'/>
                    <input type="submit"/>
                </div>
            </div>
        </div>
    );
}
export default ContactUsSmallForm;
