import logo from '../../images/logo.png';
import about_testimonials from '../../images/about_testimoinals_bg.jpg';
import CarouselHomePage from './CarouselHomePage/CarouselHomePage';
import Expertise from './Expertise/Expertise';
import s from './HomePage.module.css';
import AboutUs from "./AboutUs/AboutUs";
import Testimonials from "./Testimonials/Testimonials";
import WhyChooseUs from "./WhyChooseUs/WhyChooseUs";
import FooterHomePage from "../Footers/FooterHomePage/FooterHomePage";
import Footer from "../Footers/Footer";
import * as c from "../../state/variables";

const HomePage = (props) => {
	return(
		<div>
			{/*<a href="/registration">registration</a>*/}
			<div className="padding_qt max_width_custom menu">
				<div className="inline sp_w_100 w_20 middle menu_logo">
					<a href="#top"><img className={s.logo} src={c.API_ADDRESS+c.API_VERSION+'/domains/'+c.BRAND+'/icons/logo.png'} alt={c.BRAND + ' logo'}/></a>
				</div>
				<div className="inline sp_w_100 w_80 links menu_links">
					<div className={s.link}>
						<a className={s.scrollLink} href="#expertise">OUR EXPERTISE</a>
					</div>
					<div className={s.link}>
						<a className={s.scrollLink} href="#about">ABOUT US</a>
					</div>
					<div className={s.link}>
						<a className={s.scrollLink} href="#whyus">WHY CHOOSE US</a>
					</div>
					<div className={s.link}>
						<a className={s.scrollLink} href="#contact">CONTACT US</a>
					</div>
				</div>
			</div>
			<CarouselHomePage/>
			<Expertise/>
			<div className="w_100 max_width_custom">
				<div className={s.about_testimonials + " w_100 margin_a"} style={{backgroundImage: 'url("'+about_testimonials+'")'}}>
					<div className={s.about_testimonials_wrapper + " padding_h"}>
						<div className="w_50 sp_w_100 inline left"><AboutUs/></div>
						<div className="w_50 sp_w_100 bg_white padding_db_v inline center top"><Testimonials/></div>
					</div>
				</div>
			</div>
			<div className="w_100 max_width_custom"><WhyChooseUs/></div>
			<FooterHomePage/>
			<Footer/>
		</div>
	)
}
export default HomePage;
