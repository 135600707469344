import * as c from "./variables";
import store, {setState} from "./state";

export * from './variables'
export async function CountryCode(id) {
    // console.log(c.API_ADDRESS + " " + c.API_VERSION)
    const response = await fetch(c.API_ADDRESS + c.API_VERSION+"/CountryList/ContryPrefixCodeById.php?id="+id);
    const data = await response.json();
    document.getElementById('prefix').value = "+"+data[0].code;

    let w = document.getElementById('Email');
}

export async function CountryISD(id) {
    const response = await fetch(c.API_ADDRESS + c.API_VERSION+"/CountryList/ContryCodeByISO.php?id="+id);
    const data = await response.json();
    console.log(data[0].code)
    document.getElementById("prefixField").value = "+(" + data[0].code + ") "
    document.getElementById("Phone_NUM").value = "+(" + data[0].code + ") "
    setState("Prefix", "+(" + data[0].code + ")");
    setState("Where_Do_You_Live", data[0].id);
    return data[0].code
}
