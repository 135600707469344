import React, {Component} from 'react';
import questionClasses from "./CheckDataAnimated.module.css";

import {linesArray, questions, timeSesFunction} from '../carouselState'
import qFormCLassess from "../CarouselForm.module.css";
import tick from './tick.svg';
import {delay} from "../../../../../state/functions";
import s from "../CarouselForm.module.css";

class CheckDataAnimated extends Component{
    constructor(props) {
        super(props);
    }



    showElement(){
        // let h = 0;
        // let h_full = document.getElementById("formQuestionsWrapper").offsetHeight
        // for(let i = 0; i < questions.length + 1; i++){
        //     console.log("element_" + i);
        //     h = Math.max(h, document.getElementById("element_"+i).firstChild.offsetHeight)
        //     console.log(document.getElementById("element_"+i).firstChild.offsetHeight)
        // }
        // console.log("Max Height: " + h)
        // console.log("Max Height FULL: " + h_full)
        // console.log("Max Height FULL: " + ((h_full - h)/2))
        // for(let i = 0; i < questions.length + 1; i++){
        //     document.getElementById("element_" + i).firstChild.style.margin = (h_full - h)/2 + "px auto";
        // }

        // document.getElementById("element_0").classList.remove(qFormCLassess.active)
        // document.getElementById('element_8').classList.add(qFormCLassess.active);
        // timeSesFunction()
    }

    render(){

        delay(1000).then(() => this.showElement());
        let lines = []
        linesArray.map((question, i) =>{
            lines.push(<div className={questionClasses.element} id={"line_"+i}>
                <div className={questionClasses.confirmed}><img src={tick} alt="tick"/></div><div className={questionClasses.line}>{question}</div>
            </div>)
        })
        return(
            <div id={"element_" + this.props.step} className={questionClasses.questionWrapper + ' calculating'}>
                <div className={questionClasses.wrapper}>
                    <h2>Calculating Your Data</h2>
                    <div className={questionClasses.percent_wrapper}><span id={'percent'}>0</span><span className={questionClasses.percent_symbol}>%</span></div>
                    <div className={questionClasses.bar_wrapper}>
                        <div className={questionClasses.bar} id={'progress_bar'}>{''}</div>
                        <div className={questionClasses.bar_fill} id={'progress_bar_fill'}>{''}</div>
                    </div>
                    {lines}
                </div>
            </div>
        );
    }
}

export default CheckDataAnimated;
