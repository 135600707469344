import React from 'react';
import './index.css';
import './flexy.css';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter, Redirect} from "react-router-dom";
import store from "./state/state";
import * as c from "./state/variables";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
	autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
	debug: false, // enable logs
};
if(c.GOOGLE_CODE === 'none'){
	let pixel = c.FACEBOOK_PIXEL_ID
	ReactPixel.init(pixel, advancedMatching, options);
	ReactPixel.pageView(); // For tracking page view
}
if(c.ADS_RESOURCE === 'google'){
	const tagManagerArgs = {
		gtmId: c.GOOGLE_CODE
	}
	console.log(c.ADS_RESOURCE)
	TagManager.initialize(tagManagerArgs)
	// const TRACKING_ID = c.GOOGLE_CODE; // YOUR_OWN_TRACKING_ID
	// ReactGA.initialize(TRACKING_ID);
	// console.log("ReactGA.initialize(" + TRACKING_ID + ")")
}
document.title = c.BRAND
let rerenderEntireTree = () => {

	ReactDOM.render(

		<BrowserRouter>
			<App
				state={store._state}
			/>
		</BrowserRouter>,
		document.getElementById('root')
	);
}
rerenderEntireTree(store.getState());

store.subscribe(rerenderEntireTree);
