import React, {Component} from 'react';
import store, {setState, checkData} from '../../../../state/state'
import {delay} from "../../../../state/functions"
import s from "../CarouselForm/CarouselForm.module.css";
import * as c from '../../../../state/variables'
import {utm_campaign, utm_source, utm_term} from "../../../../state/params";
import Footer from "../../../Footers/Footer";
import Question from "./Question/Question";
import SideImages from "./SideImages/SideImages";
import classes from "../../Registration.module.css";
import {questions} from './carouselState'
import {update_images} from './functions'
import FormPersonalData from "./FormPersonalData/FormPersonalData";
import CheckDataAnimated from "./CheckDataAnimated/CheckDataAnimated";

class CarouselForm extends Component{
    constructor(props) {
        super(props);
        this.state = store._state.registrationData
    }
    checkPhone(){
        let name = "Phone"
        let value = document.getElementById("prefix").value + document.getElementById("Phone_NUM").value
        setState(name, value);
    }

    fVladik(){
        delay(1).then(() => {
            let cla = s.active;
            update_images();
            let addHeight = " - 1rem";
            if (window.innerHeight > window.innerWidth){
                addHeight = " + 6rem";
            }
            setState("Referrer_Link", document.documentURI)
            setState("UTM_Source", utm_source)
            setState("FB_Adset_Name", utm_term)
            setState("FB_Campaign_Name", utm_campaign)
            setState("FB_Ad_Name", utm_campaign)

            document.getElementById("element_0").classList.add(cla)
            let footerHeight = document.getElementsByTagName('footer')[0].clientHeight;
            let headerMain = document.getElementsByTagName('header')[0].clientHeight
            let headerSecond = document.getElementsByClassName(s.head_container_first)[0].clientHeight
            let height_value = "calc(100vh - " + footerHeight/2 + "px - " + headerMain + "px - " + headerSecond + "px" + addHeight + ")";
            document.getElementById("formQuestionsWrapper").style.height = height_value;
        });
    }

    // REACT_APP_DOMAIN='www.canadianexperts.ca' REACT_APP_SUPPORT_EMAIL='support@canadianexperts.ca' REACT_APP_BRAND='Canadian Experts' REACT_APP_PHONE_NO='1-778-3003593' REACT_APP_FACEBOOK_PIXEL_ID='1082427005620547' REACT_APP_WHATS_APP='256660015688430' REACT_APP_FACEBOOK_PIXEL_ID='380501286744852' REACT_APP_REGISTRATION_FORM_TEMPLATE='CAROUSEL_FORM' npm start

    render(){
        let steps_counter = questions.length
        let style = (100/steps_counter + 1) + "%";
        let steps = []
        this.fVladik()
        questions.map((question, i) =>{
            steps.push(<Question step={i} active={false}></Question>)
        })
        return(
            <div className={s.container}>
                <div className={s.head_container}>
                    <div className={s.head_container_first}><a href="/"><img className={s.logo} src={c.API_ADDRESS+c.API_VERSION+'/domains/'+c.BRAND+'/icons/logo.png'} alt={c.BRAND + ' logo'}/></a></div>
                    <div className={s.progressBar}>
                        <div className={s.progressBarFully} id="bar" style={{width: style}}><div className={s.progressBarFullyStep}><span id="step">1</span>/<span id="steps">{steps_counter}</span></div></div>
                    </div>
                    <div id={"formQuestionsWrapper"} className={s.questionsWrapper} >
                        <SideImages/>
                        {steps}
                        <CheckDataAnimated step={steps_counter}/>
                        <FormPersonalData step={steps_counter+1}/>
                    </div>

                </div>
                <Footer/>
            </div>
        );
    }
}

export default CarouselForm;
