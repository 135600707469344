import s from './Header.module.css';
import ReactPixel from 'react-facebook-pixel';
import callImg from '../../images/phone.png';
import mailImg from '../../images/mail.png';
import * as c from "../../state/variables";

const Header = () => {
	return <header className={s.header}>
		<link rel='stylesheet' id='oceanwp-google-font-montserrat-css'  href='https://fonts.googleapis.com/css?family=Montserrat%3A100%2C200%2C300%2C400%2C500%2C600%2C700%2C800%2C900%2C100i%2C200i%2C300i%2C400i%2C500i%2C600i%2C700i%2C800i%2C900i&amp;subset=latin&amp;ver=5.1.1' type='text/css' media='all' />
		<div className={s.divHeader}>
			<div className="w_100 max_width_custom">
				<div className={s.contact}>
					<div className="inline color_white sp_hide padding_h">
						<a className={s.contact} href={"mail:" + c.SUPPORT_EMAIL}>
							<span className="color_white padding_hf_h">{c.SUPPORT_EMAIL}</span>
							<span className="contact_icon">
								<img className="height_1" src={mailImg} alt={"mailImg"}/>
							</span>
						</a>
					</div>
					<div className="inline color_white contact">
						<a className={s.contact} href={"tel:" + c.PHONE_NO}>
							<span className="color_white">{c.PHONE_NO}</span>
							<span className="contact_icon ">
								<img className="height_1" src={callImg} alt={"callImg"}/>
							</span>
						</a>
					</div>
				</div>
			</div>
		</div>

	</header>
}
export default Header;
