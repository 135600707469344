import imageClasses from "./SideImages/SideImages.module.css";


export function update_images(){
    let image_id = Math.floor(Math.random() * (6 - 1)) + 1;
    for (let i = 1; i < 6; i++){
        document.getElementById("img_left_"+i).classList.remove(imageClasses.active_image)
        document.getElementById("img_right_"+i).classList.remove(imageClasses.active_image)
    }
    document.getElementById("img_left_" + image_id).classList.add(imageClasses.active_image)
    document.getElementById("img_right_" + image_id).classList.add(imageClasses.active_image)
}
