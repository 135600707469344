export const API_ADDRESS = "https://www.canada-direct.com/php/api/"
export const API_VERSION = "1.0"
let regForm = 'MONTHLY_INCOME'
let ads_res = 'FACEBOOK'
let googleAnalyticsCode = 'none'
let homePage = 'REGULAR';
// REACT_APP_DOMAIN='www.joincanadanow.ca' REACT_APP_SUPPORT_EMAIL='support@joincanadanow.ca' REACT_APP_BRAND='Join Canada' REACT_APP_PHONE_NO='1-778-3003593' REACT_APP_FACEBOOK_PIXEL_ID='256660015688430' REACT_APP_WHATS_APP='256660015688430' npm start
export const DOMAIN = process.env.REACT_APP_DOMAIN
export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL
export const BRAND = process.env.REACT_APP_BRAND
export const PHONE_NO = process.env.REACT_APP_PHONE_NO
export const FACEBOOK_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID
export const WHATS_APP = process.env.REACT_APP_WHATS_APP
export const REACT_APP_REGISTRATION_FORM_TEMPLATES = process.env.REACT_APP_REGISTRATION_FORM_TEMPLATES

if(process.env.REACT_APP_REGISTRATION_FORM_TEMPLATE){
    regForm = process.env.REACT_APP_REGISTRATION_FORM_TEMPLATE;
}
if(process.env.REACT_APP_ADS_RESOURCE){
    ads_res = process.env.REACT_APP_ADS_RESOURCE
}
if(process.env.REACT_APP_GOOGLE_ID){
    googleAnalyticsCode = process.env.REACT_APP_GOOGLE_ID
}
if(process.env.REACT_APP_HOMEPAGE_TEMPLATE){
    homePage = process.env.REACT_APP_HOMEPAGE_TEMPLATE;
}
//
// if(process.env.REACT_APP_WHATS_APP){console.log("REACT_APP_WHATS_APP Exist")}else{console.log("REACT_APP_WHATS_APP NOT Exist")}
export const REGISTRATION_FORM_TEMPLATE = regForm
export const ADS_RESOURCE = ads_res
export const GOOGLE_CODE = googleAnalyticsCode
export const HOMEPAGE_TEMPLATE = homePage

export const STEPS = [{id: 1,text: 'Eligibility'},{id: 2,text: 'Registration'},{id: 3,text: 'Confirmation'}]
