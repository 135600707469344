import * as c from "./variables";
import axios from "axios";
import userScore from './userScore'
import classes from '../components/Registration/Registration.module.css';
let resource = 'Facebook';
if(c.GOOGLE_CODE !== 'none'){
    resource = 'Google';
}
let store = {
    _state : {
        registrationData:{
            First_Name: '',
            Last_Name: '',
            Email: '',
            Where_Do_You_Live: '',
            Phone: '',
            Birth_Country: '',
            Age: '',
            Marital_Status: '',
            Speaking_English_Level: '',
            Education_Level: '',
            Been_in_Canada: '',
            LeadType: '',
            Savings: '',
            Monthly_income_USD:'',
            Business_Owner: '',
            When_would_you_like_to_immigrate: '',
            What_visa_are_you_interested_in: '',
            Lead_Status: 'New',
            Referrer_Link: '',
            UTM_Source: '',
            FB_Campaign_Name: '',
            FB_Adset_Name: '',
            FB_Ad_Name: '',
            Customer_Score: '',
            Brand: c.BRAND,
            Lead_Source: resource,
            Currency: 'USD',
            AgreeInput: ''
        },
        userData: {
            country:'',
            birthCountry: '',
            zohoid:''
        },
        domainData:{
            DOMAIN: c.DOMAIN,
            SUPPORT_EMAIL: c.SUPPORT_EMAIL,
            BRAND: c.BRAND,
            PHONE_NO: c.PHONE_NO,
            FACEBOOK_PIXEL_ID: c.FACEBOOK_PIXEL_ID,
            WHATS_APP: c.WHATS_APP
        },
        _mainData: {
            domain: c.DOMAIN,
            support_email: c.SUPPORT_EMAIL,
            brand: c.BRAND
        }
    },
    _setState2(state){
        this._state = state
    },
    getState(){
        return this._state;
    },
    subscribe(observer){
        this._callSubscriber = observer;
    },
}
function saveDataToLocalStorage() {
    window.localStorage.setItem('state',JSON.stringify(store._state))
}

export const setState = (target, value) => {
    store._state.registrationData[target] = value;
    // console.log(target + ": " + value)
}

export const removeAllRedAlerts = () => {
    let cl = classes.alert_border
    for(const [key, value] of Object.entries(store._state.registrationData)){
        if(key === 'LeadType') continue;
        // if(key === 'Savings') continue;
        if(key === 'Monthly_income_USD') continue;
        if(value === ''){
            try {
                if(document.getElementById(key).classList !== null){
                    document.getElementById(key).classList.remove(cl)
                }
            }catch (e) {
                console.log(e);
            }
        }
    }
}
export const checkData = () =>{
    const data = store._state.registrationData;
    // console.log(data)
    axios.post(c.API_ADDRESS + c.API_VERSION + '/CreateLead/CreateLead.php', data)
    .then(res =>{
        if(res.data.code === 100){
            // console.log("res.data.code == 100")
            window.location.href = res.data.refferer_link;
        }else if(res.data.code === '-401'){
            alert(res.data.msg);
            document.getElementById('checkBtn').style.pointerEvents = 'auto';
            document.getElementById('checkBtn').onclick = true;
        }else{
            alert("Please check the " + res.data.msg + " field and try again.");
            document.getElementById('checkBtn').style.pointerEvents = 'auto';
            document.getElementById('checkBtn').onclick = true;
            // console.log(res)
        }
    })
}
export const updateData = (field, value) =>{
    if(field === 'Phone'){value = document.getElementById("prefix").value + value}
    store._state.registrationData.Customer_Score = userScore(store._state.registrationData)
    store._state.registrationData[field] = value
    saveDataToLocalStorage()
}

export const getCookies = function(response){
    const cookies = {}
    for(const [name, values] of response.headers){
        if(name === 'set-cookie'){
            for(const cookie of values.split(';')){
                const [key, value] = cookie.split('=')
                cookies[key] = value
            }
        }
    }
    // console.log(cookies)
    return cookies
}
export function uploadDataFromLocalStorageToState() {
    let info = JSON.parse(window.localStorage.getItem('state'))
    store._setState2(info)
}

export default store;
