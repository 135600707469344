// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./process.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StepsHeader_info__2-Ecx{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n.StepsHeader_info__2-Ecx .StepsHeader_fill_text__aDBXR{\n    font-size: 1.4em;\n    font-weight: 600;\n}\n.StepsHeader_info__2-Ecx .StepsHeader_fill_text_good_luck__l01uU{\n    font-size: 2.5rem;\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/DifferenceComponents/StepsHeader/StepsHeader.module.css"],"names":[],"mappings":"AAAA;IACI,yDAAoC;IACpC,4BAA4B;IAC5B,sBAAsB;AAC1B;AACA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".info{\n    background-image: url('process.png');\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n.info .fill_text{\n    font-size: 1.4em;\n    font-weight: 600;\n}\n.info .fill_text_good_luck{\n    font-size: 2.5rem;\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": "StepsHeader_info__2-Ecx",
	"fill_text": "StepsHeader_fill_text__aDBXR",
	"fill_text_good_luck": "StepsHeader_fill_text_good_luck__l01uU"
};
export default ___CSS_LOADER_EXPORT___;
