// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SideImages_image_side__3x-LV{\n    position: absolute;\n    opacity: 0;\n    transition: opacity .5s;\n}\n.SideImages_image_top__2mVtf{\n    top: 0;\n}\n.SideImages_image_left__U3Kue{\n    left: 0;\n}\n.SideImages_image_bottom__2FYoB{\n    bottom: 0;\n}\n.SideImages_image_right__2P9k0{\n    right: 0;\n}\n.SideImages_active_image__38aaX{\n    opacity: 1;\n    transition: opacity .5s;\n}\n\n@media screen and (max-width: 680px) {\n    .SideImages_image_side__3x-LV{\n        width: 30vw;\n    }\n}\n", "",{"version":3,"sources":["webpack://src/components/Registration/RegistrationForm/CarouselForm/SideImages/SideImages.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,uBAAuB;AAC3B;AACA;IACI,MAAM;AACV;AACA;IACI,OAAO;AACX;AACA;IACI,SAAS;AACb;AACA;IACI,QAAQ;AACZ;AACA;IACI,UAAU;IACV,uBAAuB;AAC3B;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".image_side{\n    position: absolute;\n    opacity: 0;\n    transition: opacity .5s;\n}\n.image_top{\n    top: 0;\n}\n.image_left{\n    left: 0;\n}\n.image_bottom{\n    bottom: 0;\n}\n.image_right{\n    right: 0;\n}\n.active_image{\n    opacity: 1;\n    transition: opacity .5s;\n}\n\n@media screen and (max-width: 680px) {\n    .image_side{\n        width: 30vw;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image_side": "SideImages_image_side__3x-LV",
	"image_top": "SideImages_image_top__2mVtf",
	"image_left": "SideImages_image_left__U3Kue",
	"image_bottom": "SideImages_image_bottom__2FYoB",
	"image_right": "SideImages_image_right__2P9k0",
	"active_image": "SideImages_active_image__38aaX"
};
export default ___CSS_LOADER_EXPORT___;
