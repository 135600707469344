import s from './EmailConfirmationPage.module.css';
import axios from "axios";
import * as c from "../../state/variables";
import store from "../../state/state";
import ReactPixel from 'react-facebook-pixel';


export const checkData = () =>{
	let email = document.getElementById("email").value;
	let product = document.getElementById('product').value;
	let category = document.getElementById('category').value;
	document.getElementById('confirm_btn').style.pointerEvents = 'none';
	axios.post(c.API_ADDRESS + c.API_VERSION + '/functions/emailConfirm.php'
			+'?email=' + email
			+ "&product=" + product
			+ "&category=" + category
	).then(res =>{
		if(res.data.code == 300){
			document.getElementById('text_header').textContent = res.data.msg;
			document.getElementById('confirm_btn').style.pointerEvents = 'auto';
			document.getElementById("email").value = '';
			document.getElementById("email").focus()
		}
		if(res.data.code < 300){
			document.getElementById('confirm_text').textContent = res.data.msg;
			document.getElementsByClassName(s.confirm)[0].classList.add(s.active)
			document.getElementsByClassName(s.input_wrapper)[0].classList.add(s.disable)
			if(res.data.code === 200){
				window.fbq('track', 'email confirmation');
			}
		}

		console.log(res)
	})
}

const EmailConfirmationPage = () => {
	document.title = document.title + " thank you!"
	let search = window.location.search;
	let params = new URLSearchParams(search);
	let product = params.has('product')?params.get('product') : 'no product';
	let category = params.has('category')?params.get('category') : 'Leads';
	return(
		<div>
			<div className="wrapper">
				<div className={s.transparent_bg}>
					<div className={s.thankyou}>
						<div className={s.header}>Thank You For Your Interest!</div>
						<div className={s.divider + ' w_10 sp_w_30 color_white margin_a'}></div>
						<br className="hide sp_show"/>
						<div className={s.form_wrapper}>
							<div className={s.confirm}>
								<h2 className={s.h2} id='confirm_text'>We will contact in the next 24H with the results.</h2>
							</div>
							<div className={s.input_wrapper}>
								<h2 className={s.h2 + " center"} id='text_header'>Please confirm Your Email Address</h2>
								<input type="email" placeholder="Email" id="email"/>
								<input className="hide" type="text" value={product} id="product"/>
								<input className="hide" type="text" value={category} id="category"/>
								<div className={s.confirm_btn} id="confirm_btn" onClick={checkData}>Confirm</div>
							</div>

						</div>

					</div>
				</div>
			</div>
		</div>
	)
}
export default EmailConfirmationPage;
