import React, {Component} from 'react';
import store, {setState, checkData, removeAllRedAlerts} from '../../../../state/state'
import {delay} from "../../../../state/functions"
import s from "../SimpleForm/SimpleForm.module.css";
import {CountryCode} from "../../../../state/getCountryCode";
import CountryListClass from "../CountryListClass/CountryListClass";
import {utm_campaign, utm_source, utm_term} from "../../../../state/params";
import Footer from "../../../Footers/Footer";
import classes from "../../Registration.module.css";
import StepsHeader from "../../../DifferenceComponents/StepsHeader/StepsHeader";

class SimpleForm extends Component{
    constructor(props) {
        super(props);
        this.state = store._state.registrationData
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.agreeFieldUpdate()
    }
    checkPhone(){
        let name = "Phone"
        let value = document.getElementById("prefix").value + document.getElementById("Phone_NUM").value
        setState(name, value);
    }


    updateAgreeFieldAndState(){
        document.getElementById("AgreeInput").checked = true;
        let val = document.getElementById("AgreeInput").checked;
        setState("AgreeInput", val);
    }

    agreeFieldUpdate(){
        delay(1000).then(() => this.updateAgreeFieldAndState());
    }
    cleanPhone(){
        document.getElementById('Phone_NUM').value = document.getElementById('Phone_NUM').value.replaceAll(/[^0-9.]/g,"")
    }

    onInputChange(event){
        let name = event.target.name;
        let value = event.target.value;
        let cb = s.custom_background;
        if(value !== ''){
            document.getElementById(name).classList.add(cb);
        }else{
            document.getElementById(name).classList.remove(cb);
        }
        if(name === 'AgreeInput'){
            value = document.getElementById("AgreeInput").checked;
        }
        if(name === 'Phone_NUM'){
            name = "Phone"
            value = document.getElementById("prefix").value + document.getElementById("Phone_NUM").value
        }

        // console.log(cb)
        setState(name, value);
    }

    removeAllRedAlertsSimpleForm(){
        let cl = classes.alert_border
        for(const [key, value] of Object.entries(this.state)){
            if(value === '' || value === false){
                try {
                    if(document.getElementById(key).classList !== null){
                        document.getElementById(key).classList.remove(cl)
                    }
                }catch (e) {
                    // console.log("key:" + key + ", Value: " + value + "\n\n" + e);
                }
            }
        }
        document.getElementById('checkBtn').style.pointerEvents = 'auto';
        document.getElementById('checkBtn').onclick = true;
    }

    onSubmitForm(){
        // checkData()
        document.getElementById('checkBtn').style.pointerEvents = 'none';
        document.getElementById('checkBtn').onclick = false;
        console.clear()
        const data = this.state
        // console.log(data)
        let countrer_error_fields = 0;
        let cl = classes.alert_border
        for(const [key, value] of Object.entries(data)){
            if(key === 'LeadType') continue;
            if(key === 'Monthly_income_USD') continue;
            if(key === 'FB_Ad_Name') continue;
            if(key === 'UTM_Source') continue;
            if(key === 'FB_Campaign_Name') continue;
            if(key === 'FB_Adset_Name') continue;
            if(key === 'Referrer_Link') continue;
            if(key === 'Customer_Score') continue;
            if(value === '' || value === undefined || value === false){
                countrer_error_fields ++
                try{
                    // console.log(key + " ::::: Added Error Class ")
                    document.getElementById(key).classList.add(cl)
                }catch (e) {
                    // console.log(key + " ::::: " + e)
                }
            }
        }
        if(countrer_error_fields > 0){
            // console.log("countrer_error_fields > 0")
            if(countrer_error_fields === 1 && document.getElementById("AgreeInput").checked === false){
                alert("You should agree with Terms of Service and Privacy Policy")
                // document.getElementById("AgreeInput").parentElement.parentElement.classList.add(cl);
                // delay(1000).then(() => document.getElementById("AgreeInput").parentElement.parentElement.classList.remove(cl));
            }else{
                alert("Please fill all empty fields!")
            }
            // console.log(countrer_error_fields)

        }else{
            checkData()
            // console.log("ALL GOOD")
        }
        delay(1000).then(() => this.removeAllRedAlertsSimpleForm());
    }

    render(){
        const {items} = this.state;
        return(
            <div>
                <StepsHeader currentStep={1}/>
                <form className='max_width_1024'>
                    <div className={s.form_fields + ' w_90 margin_a sp_w_95 form_wrapper form_fields'}>
                        <div className={s.check + ' w_100 padding_db_v color_red realtive'}>
                            <img src={"/img/Registration/arrow.png"} alt={'arrow'}/>CHECK YOUR ELIGIBILITY
                        </div>
                        <div className="w_50 center sp_w_100 padding_b inline">
                            <input id="First_Name"
                                   onChange={this.onInputChange}
                                   className={s.inputField}
                                   type="text"
                                   name="First_Name"
                                   placeholder="First name"
                                   required
                            />
                        </div>
                        <div className="w_50 center sp_w_100 inline padding_b">
                            <input id="Last_Name"
                                   onChange={this.onInputChange}
                                   className={s.inputField}
                                   type="text"
                                   name="Last_Name"
                                   placeholder="Last Name"
                                   required
                            />
                        </div>
                        <div className="w_50 center sp_w_100 padding_b inline">
                            <input 	id="Email"
                                      className={s.inputField}
                                      onChange={this.onInputChange}
                                      type="email"
                                      name="Email"
                                      placeholder="Email"
                                      required={true}
                            />
                        </div>
                        <CountryListClass name="Where_Do_You_Live"/>
                        <div className="w_50 center sp_w_100 padding_b inline">
                            <div className="w_100">
                                <input type="tel"
                                       id="prefix"
                                       className={s.inputFieldPrefix}
                                       disabled={true}
                                />
                                <input id="Phone_NUM"
                                       className={s.inputFieldPhone}
                                       type="tel"
                                       name="Phone_NUM"
                                       onChange={this.onInputChange}
                                       onKeyUp={this.cleanPhone}
                                       placeholder="&nbsp;Your Best Phone Number"
                                       required
                                       onBlur={this.checkPhone}
                                />
                            </div>
                        </div>
                        <CountryListClass name="Birth_Country"/>
                        <div className="w_50 center sp_w_100 padding_b inline">
                            <input id="Age"
                                   className={s.inputField}
                                   onChange={this.onInputChange}
                                   type="tel"
                                   name="Age"
                                   placeholder="Age"
                                   required
                                   maxLength="3"
                                   minLength="2"
                            />
                        </div>
                        <div className="w_50 center sp_w_100 inline padding_b">
                            <select
                                id="Marital_Status"
                                onChange={this.onInputChange}
                                className={s.select_field}
                                name="Marital_Status"
                                required
                            >
                                <option value="">Marital Status</option>
                                <option value="common-law marriage">Common-law marriage</option>
                                <option value="Divorced">Divorced</option>
                                <option value="Legally separated">Legally separated</option>
                                <option value="Married">Married</option>
                                <option value="Single">Single</option>
                                <option value="Widowed">Widowed</option>
                            </select>
                        </div>
                        <div className="w_50 center sp_w_100 padding_b inline">
                            <select
                                id="Speaking_English_Level"
                                className={s.select_field}
                                name="Speaking_English_Level"
                                onChange={this.onInputChange}
                                required
                            >
                                <option value="">Speaking English Level</option>
                                <option value="No English">No English</option>
                                <option value="Basic">Basic</option>
                                <option value="Medium">Medium</option>
                                <option value="Advanced">Advanced</option>
                                <option value="Proficient">Proficient</option>
                            </select>
                        </div>
                        <div className="w_50 center sp_w_100 inline padding_b">
                            <select
                                id="Education_Level"
                                className={s.select_field}
                                name="Education_Level"
                                onChange={this.onInputChange}
                                required
                            >
                                <option value="">Education Level</option>
                                <option value="Primary school only">Primary school only</option>
                                <option value="High School Degree">High School Degree</option>
                                <option value="Vocational School">Vocational School</option>
                                <option value="University Degree">University Degree</option>
                                <option value="Master Degree">Master Degree</option>
                                <option value="Doctorate Degree">Doctorate Degree</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div className="w_50 center sp_w_100 padding_b inline">
                            <select
                                id="Been_in_Canada"
                                className={s.select_field}
                                name="Been_in_Canada"
                                onChange={this.onInputChange}
                                required
                            >
                                <option value="">Have you been in Canada?</option>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                            </select>
                        </div>
                        <div className="w_50 center sp_w_100 inline padding_b">
                            <p className="w_85 margin_a padding_qt_b"><label className="f_09 sp_f_11"><b>How much money do
                                you plan to bring to Canada (American dollars)</b></label></p>
                            <select
                                id="Savings"
                                className={s.select_field}
                                name="Savings"
                                onChange={this.onInputChange}
                                required
                            >
                                <option value="">Please Choose</option>
                                <option value="0-$10,000">0-$10,000</option>
                                <option value="$10,000-$30,000">$10,000-$30,000</option>
                                <option value="$30,000-$60,000">$30,000-$60,000</option>
                                <option value="$60,000+">$60,000+</option>
                            </select>
                        </div>
                        <div className="w_50 center sp_w_100 padding_b inline">
                            <select id="What_visa_are_you_interested_in"
                                    className={s.select_field}
                                    name="What_visa_are_you_interested_in"
                                    onChange={this.onInputChange}
                                    required
                            >
                                <option value="">Which visa are you interested</option>
                                <option value="Permanent Residency Visa">Permanent Residency Visa</option>
                                <option value="Work Visa">Work Visa</option>
                                <option value="Student Visa">Student Visa</option>
                                <option value="Working Holiday (IEC)">Working Holiday (IEC)</option>
                                <option value="Tourist">Tourist</option>
                            </select>
                        </div>
                        <div className="w_50 middle center sp_w_100 inline padding_b">
                            <select id="When_would_you_like_to_immigrate"
                                    className={s.select_field}
                                    name="When_would_you_like_to_immigrate"
                                    onChange={this.onInputChange}
                                    required>
                                <option value="">When would you like to immigrate?</option>
                                <option value="0-1 Year">As soon as possible</option>
                                <option value="1-2 Years">1-2 Years</option>
                                <option value="2-3 Years">2-3 Years</option>
                                <option value="3-5 Years">3-5 Years</option>
                                <option value="5+">5+ Years</option>
                            </select>
                        </div>
                        <div className="w_50 middle center padding_b sp_w_100 inline">
                            <select
                                id="Business_Owner"
                                className={s.select_field}
                                name="Business_Owner"
                                onChange={this.onInputChange}
                                required>
                                <option value="">Are You a Business Owner?</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                        <div className="w_50 middle center sp_w_100 inline padding_b">
                            <div className="w_80 margin_a">
                                <label><input className="input"
                                              onChange={this.onInputChange}
                                              id="AgreeInput"
                                              name="AgreeInput"
                                              type="checkbox"
                                              required
                                              aria-invalid="false"
                                    // checked="true"
                                /><span>Yes, I agree to the <a
                                    href="/terms-of-service" target="_blank">Terms of Service </a> and <a
                                    href="/privacy-policy" target="_blank">Privacy Policy </a>and would like to receive my login details on Email.</span></label>
                            </div>
                        </div>
                        <div className="w_50 center sp_w_100 sp_padding_b inline">
                            <div className="w_85 margin_a">
                                <div
                                    id="checkBtn"
                                    disabled={true}
                                    onClick={this.onSubmitForm}
                                    className={s.check_btn + " button check_btn margin_a sp_w_80 color_white button_red bg_red w_85 center uppercase padding"}>
                                    <span className="ladda-label">Check now</span></div>
                            </div>
                        </div>
                        <div className="hide">
                            <input type="text" readOnly={true} name="Referrer_Link" id="Referrer_Link" value={utm_source}/>
                            <input type="text" readOnly={true} name="UTM_Source" id="UTM_Source" value={utm_source}/>
                            <input type="text" readOnly={true} name="FB_Campaign_Name" id="FB_Campaign_Name" value={utm_campaign}/>
                            <input type="text" readOnly={true} name="FB_Adset_Name" id="FB_Adset_Name" value={utm_term}/>
                        </div>
                    </div>
                </form>
                <Footer/>
            </div>
        );
    }
}

export default SimpleForm;
