import {update_images} from "./functions";
import qFormCLassess from "./CarouselForm.module.css";
import {delay} from "../../../../state/functions";
import store, {setState} from '../../../../state/state'
import s from "../SimpleForm/SimpleForm.module.css";
import classes from "../../Registration.module.css";
import questionClasses from "./CheckDataAnimated/CheckDataAnimated.module.css";

export const questions = [
    {question: "Which visa are you interested in",
        line: "What_visa_are_you_interested_in",
        answers: [
            "Permanent Residency Visa",
            "Work Visa",
            "Student Visa",
            "Working Holiday (IEC)",
            "Tourist"
        ]
    },
    {question: "Are You Buisness Owner?",
        line: "Business_Owner",
        answers: ["Yes", "No"]},
    {question: "English Speaking Level",
        line: "Speaking_English_Level",
        answers: [
            "No English",
            "Basic",
            "Medium",
            "Advanced",
            "Proficient"
        ]
    },
    {question: "Education Level",
        line: "Education_Level",
        answers: [
            "Primary school only",
            "High School Degree",
            "Vocational School",
            "University Degree",
            "Master Degree",
            "Doctorate Degree",
            "Other"
        ]
    },
    {question: "Marital Status",
        line: "Marital_Status",
        answers: [
        "Common-law marriage",
        "Divorced",
        "Legally separated",
        "Married",
        "Single",
        "Widowed"
        ]
    },{question: "Savings",
        line: "Savings",
        answers: [
        "0-$10,000",
        "$10,000-$30,000",
        "$30,000-$60,000",
        "$60,000+"]
    },{question: "When would you like to immigrate?",
        line: "When_would_you_like_to_immigrate",
        answers: [
        "As soon as possible",
        "1-2 Years",
        "2-3 Years",
        "3-5 Years",
        "5+ Years"
    ]
    },{
        question: "Have You Been in Canada",
        line: "Been_in_Canada",
        answers: [
            "Yes",
            "No"
        ]
    }
]

export const TIME_INTERVAL = 1500

export const linesArray = [
    "Analysing your answers.",
    "Checking your eligibility.",
    "Checking your immigration options.",
    "Your results are ready!"
]

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}
export async function changePercent(from, to, element){
    const intervalId = setInterval(() => {
        from = from + 1;
        document.getElementById("percent").innerText = from
        document.getElementById("progress_bar_fill").style.width = from + '%';
        if(from >= to){
            document.getElementById("line_"+element).classList.add(questionClasses.confirm_confirmed)
            clearInterval(intervalId);
        }
        document.getElementById("line_"+element).classList.add(questionClasses.confirm_line)
    }, Math.floor(TIME_INTERVAL/(100/linesArray.length)),0);
}
export async function setAllQuestion(old_id, new_id){
    await sleep(250)
    for(let i = 0; i < linesArray.length; i++){
        document.getElementById("line_"+i).classList.remove(questionClasses.confirm_confirmed)
        await sleep(100)
    }
    for(let i = 0; i < linesArray.length; i++){
        document.getElementById("line_"+i).classList.add(questionClasses.confirm_confirmed)
        await sleep(100)
    }
    await sleep(1000);
    document.getElementById('element_' + old_id).classList.remove(qFormCLassess.active)
    document.getElementById('element_' + new_id).classList.add(qFormCLassess.active)

}
export async function timeSesFunction(id){
    let old_id = id + 1;
    let new_id = id + 2;
    document.getElementById("AgreeInput").checked = true;
    setState("AgreeInput", true);
    for(let i = 0; i < linesArray.length; i++){
        let from = Math.floor(100 / linesArray.length * (i), 0)
        let to = Math.floor(100 / linesArray.length * (i + 1), 0)
        await changePercent(from, to, i)
        await sleep(TIME_INTERVAL)
    }
    setAllQuestion(old_id, new_id);
}
export function onInputChange(event){
    let name = event.target.name;
    let value = event.target.value;
    let cb = s.custom_background;
    if(value !== ''){
        document.getElementById(name).classList.add(cb);
    }else{
        document.getElementById(name).classList.remove(cb);
    }
    if(name === 'AgreeInput'){
        value = document.getElementById("AgreeInput").checked;
    }
    if(name === 'Phone_NUM'){
        name = "Phone"
        value = document.getElementById("Phone_NUM").value.replaceAll(/[^0-9+]/g,"")
    }

    // console.log(cb)
    setState(name, value);
    // console.log(store.getState().registrationData)
}

export function cleanPhone(){
    document.getElementById('Phone_NUM').value = document.getElementById('Phone_NUM').value.replaceAll(/[^()0-9+ .]/g,"")
}

export function cleanField(id){
    document.getElementById(id).value = document.getElementById(id).value.replaceAll(/[^a-zA-Z0-9 .]/g,"")
}

export function _showQuestionByIdNew(question, answer){
    let len = questions.length + 1
    update_images()
    if(question === 'When_would_you_like_to_immigrate'){
        if(answer === 'As soon as possible'){
            answer = '0-1 Year'
        }
    }
    setState(question, answer);
    let new_id = 1;
    for (let i = 0; i < len; i++ ){
        let id = "element_" + i;
        if (document.getElementById(id).classList.contains(qFormCLassess.active) && i < len-1){

            document.getElementById('bar').style.width=(100/len*(i+2) + 1) + "%";
            document.getElementById('step').innerText = i+2
            document.getElementById('steps').innerText = len
            new_id = "element_" + (i + 1);
            if(document.getElementById(new_id).classList.contains('calculating')){
                timeSesFunction(i)
            }
            document.getElementById(id).classList.remove(qFormCLassess.active)
            delay(100).then(() => document.getElementById(new_id).classList.add(qFormCLassess.active));
            break;
        }
    }
}

export function oneStepBack(){
    let len = questions.length
    let new_id = 1;
    update_images()
    for (let i = 0; i < len; i++ ){
        let id = "element_" + i;
        if (document.getElementById(id).classList.contains(qFormCLassess.active) && i > 0){
            document.getElementById('bar').style.width=(100/len*(i) + 1) + "%";
            document.getElementById('step').innerText = i
            new_id = "element_" + (i-1);
            document.getElementById(id).classList.remove(qFormCLassess.active)
            delay(100).then(() => document.getElementById(new_id).classList.add(qFormCLassess.active));
            break;
        }
    }
}

export function removeAllRedAlertsSimpleForm(){
    let cl = classes.alert_border
    for(const [key, value] of Object.entries(store._state.registrationData)){
        if(value === '' || value === false){
            try {
                if(document.getElementById(key).classList !== null){
                    document.getElementById(key).classList.remove(cl)
                }
            }catch (e) {
                // console.log("key:" + key + ", Value: " + value + "\n\n" + e);
            }
        }
    }
    document.getElementById('checkBtn').style.pointerEvents = 'auto';
    document.getElementById('checkBtn').onclick = true;
}
