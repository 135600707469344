// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_header__1VCKf{\n\tbackground-color: black;\n\t/*grid-area: h;*/\n\t/*background-color: forestgreen;*/\n}\n.Header_divHeader__2HAQE{\n\twidth: 4rem;\n\twidth: 100%;\n\twhite-space: nowrap;\n\ttext-align: right;\n}\n.Header_max_width__eSagE{\n\tmax-width: 1024px;\n\tmargin: auto;\n}\n.Header_contact__3kvNe{\n\ttext-decoration: none;\n\tpadding-right: 2rem;\n\twidth: 45%;\n\tdisplay: inline-block;\n\tcolor: white;\n\tpadding: .25rem;\n\tdirection: rtl;\n\tfont-size: .9rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;CACvB,gBAAgB;CAChB,iCAAiC;AAClC;AACA;CACC,WAAW;CACX,WAAW;CACX,mBAAmB;CACnB,iBAAiB;AAClB;AACA;CACC,iBAAiB;CACjB,YAAY;AACb;AACA;CACC,qBAAqB;CACrB,mBAAmB;CACnB,UAAU;CACV,qBAAqB;CACrB,YAAY;CACZ,eAAe;CACf,cAAc;CACd,gBAAgB;AACjB","sourcesContent":[".header{\n\tbackground-color: black;\n\t/*grid-area: h;*/\n\t/*background-color: forestgreen;*/\n}\n.divHeader{\n\twidth: 4rem;\n\twidth: 100%;\n\twhite-space: nowrap;\n\ttext-align: right;\n}\n.max_width{\n\tmax-width: 1024px;\n\tmargin: auto;\n}\n.contact{\n\ttext-decoration: none;\n\tpadding-right: 2rem;\n\twidth: 45%;\n\tdisplay: inline-block;\n\tcolor: white;\n\tpadding: .25rem;\n\tdirection: rtl;\n\tfont-size: .9rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Header_header__1VCKf",
	"divHeader": "Header_divHeader__2HAQE",
	"max_width": "Header_max_width__eSagE",
	"contact": "Header_contact__3kvNe"
};
export default ___CSS_LOADER_EXPORT___;
