import s from './Step.module.css';


const Step = (props) => {
	let current_step = props.id == props.current ? ' ' + s.current_step : '';
	let finsihed_step = props.id < props.current ? ' ' + s.finished_step : '';
	return(
		<div style={{width: 'calc(100%/'+props.counter +')'}} id={"step_" + props.id} className={current_step + ' ' + finsihed_step + 'center top inline step'}>
			<div className={current_step + ' w_40 margin_a circle'}>
				<div data="data" className={s.number + current_step + finsihed_step}>{props.id}
					<p className={s.steps_delimetr}></p>
				</div>
				<i className="arrow right"></i>
			</div>
			<div className={s.text}>{props.text}</div>
		</div>
	)
}
export default Step;
