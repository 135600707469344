import React, {Component} from 'react';
import questionClasses from "./Question.module.css";

import {questions, _showQuestionByIdNew, oneStepBack} from '../carouselState'

class Question extends Component{
    constructor(props) {
        // const step = props.step
        super(props);
    }


    render(){
        const step = this.props.step
        let answers = []
        questions[step].answers.map((answer, i) =>{
            // answers.push(<div data-answer={answer} data-question={questions[step].question} className={questionClasses.answer} onClick={this.showQuestionByIdNew}>{answer}</div>)
            answers.push(<div data-answer={answer} data-question={questions[step].question} className={questionClasses.answer} onClick={()=>_showQuestionByIdNew(questions[step].line, answer)}>{answer}</div>)
        })
        return(
            <div className={questionClasses.questionWrapper} id={'element_' + this.props.step} style={{height: this.props.height}}>
                <div className={questionClasses.questionForm} >
                    <h2>{questions[step].question}</h2>
                        {answers}
                    <h3 className={questionClasses.backBtn} onClick={oneStepBack}><span>&#60;&#60;</span> Back</h3>
                </div>
            </div>
        );
    }
}

export default Question;
