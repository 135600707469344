import React, {Component} from 'react';
import styles from "./SideImages.module.css";

import left_1 from './images/left_1.png';
import left_2 from './images/left_2.png';
import left_3 from './images/left_3.png';
import left_4 from './images/left_4.png';
import left_5 from './images/left_5.png';

import right_1 from './images/right_1.png';
import right_2 from './images/right_2.png';
import right_3 from './images/right_3.png';
import right_4 from './images/right_4.png';
import right_5 from './images/right_5.png';

class SideImages extends Component{


    constructor(props) {
        super(props);
    }


    render(){

        return(
            <div>
                <img id="img_left_1" className={styles.image_side + ' ' + styles.image_top + ' ' + styles.image_left} src={left_1} alt=""/>
                <img id="img_left_2" className={styles.image_side + ' ' + styles.image_bottom + ' ' + styles.image_left} src={left_2} alt=""/>
                <img id="img_left_3" className={styles.image_side + ' ' + styles.image_top + ' ' + styles.image_left} src={left_3} alt=""/>
                <img id="img_left_4" className={styles.image_side + ' ' + styles.image_top + ' ' + styles.image_left} src={left_4} alt=""/>
                <img id="img_left_5" className={styles.image_side + ' ' + styles.image_bottom + ' ' + styles.image_left} src={left_5} alt=""/>

                <img id="img_right_1" className={styles.image_side + ' ' + styles.image_bottom + ' ' + styles.image_right} src={right_1} alt=""/>
                <img id="img_right_2" className={styles.image_side + ' ' + styles.image_top + ' ' + styles.image_right} src={right_2} alt=""/>
                <img id="img_right_3" className={styles.image_side + ' ' + styles.image_bottom + ' ' + styles.image_right} src={right_3} alt=""/>
                <img id="img_right_4" className={styles.image_side + ' ' + styles.image_bottom + ' ' + styles.image_right} src={right_4} alt=""/>
                <img id="img_right_5" className={styles.image_side + ' ' + styles.image_top + ' ' + styles.image_right} src={right_5} alt=""/>            </div>
        );
    }
}

export default SideImages;
