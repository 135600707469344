import React, {Component} from 'react';
import questionClasses from "../Question/Question.module.css";
import CountryListSimple from "../../CountryListSimple/CountryListSimple"
import {CountryISD} from '../../../../../state/getCountryCode';
import {delay} from "../../../../../state/functions"
import {
    onInputChange,
    cleanPhone,
    cleanField,
    removeAllRedAlertsSimpleForm
} from '../carouselState'

import fpdClasses from "./FormPersonalData.module.css"
import classes from "../../../Registration.module.css";
import store, {checkData} from "../../../../../state/state";

import './phoneInput.css'
import PhoneInput from 'react-phone-number-input'
import {utm_campaign, utm_source, utm_term} from "../../../../../state/params";

class FormPersonalData extends Component{
    constructor(props) {
        super(props);
        this.state = store._state.registrationData
    }
    onSubmitForm(){
        document.getElementById('checkBtn').style.pointerEvents = 'none';
        document.getElementById('checkBtn').onclick = false;
        console.clear()
        let countrer_error_fields = 0;
        let cl = classes.alert_border
        for(const [key, value] of Object.entries(store._state.registrationData)){
            if(key === 'LeadType') continue;
            if(key === 'Monthly_income_USD') continue;
            if(key === 'FB_Ad_Name') continue;
            if(key === 'UTM_Source') continue;
            if(key === 'FB_Campaign_Name') continue;
            if(key === 'FB_Adset_Name') continue;
            if(key === 'Referrer_Link') continue;
            if(key === 'Customer_Score') continue;
            if(value === '' || value === undefined || value === false){
                countrer_error_fields ++
                try{
                    document.getElementById(key).classList.add(cl)
                }catch (e) {
                    console.log(key + " ::::: " + e)
                }
            }
        }
        if(countrer_error_fields == 0){
            console.log("Lets try!");
            checkData()
        }else{
            console.log(countrer_error_fields)
            console.log(store._state.registrationData)
            setTimeout(function(){
                removeAllRedAlertsSimpleForm()
            }, 1000)
        }

    }
    testFunc(){
        console.log("Hello")
    }
    handleChange(e) {
        let id = document.getElementsByClassName('PhoneInputCountrySelect')[0].value
        console.log(CountryISD(id))
    }
    render(){
        return(
            <div id={"element_" + this.props.step} className={questionClasses.questionWrapper}>
                <div className={fpdClasses.personalFormWrapper}>
                    <h2>
                        Enter Your Details And Start Your Journey
                    </h2>
                    <div className={fpdClasses.question}>
                        <input className={fpdClasses.inputField}
                               onKeyUp={()=>cleanField("Last_Name")}
                               onChange={onInputChange}
                               type="text"
                               name="Last_Name"
                               placeholder={"Last Name"}
                               id={"Last_Name"}/>
                    </div>
                    <div className={fpdClasses.question}>
                        <input className={fpdClasses.inputField}
                               type="text"
                               name="First_Name"
                               onKeyUp={()=>cleanField("First_Name")}
                               // onKeyUp={cleanField}
                               onChange={onInputChange}
                               placeholder={"First Name"}
                               id={"First_Name"}/>
                    </div>
                    <input type="text" className={"hide"} id={"prefixField"}/>
                    <input type="text" className={"hide"} id={"phoneField"}/>
                    {/*<div className={fpdClasses.question}>*/}
                    {/*    <CountryListSimple className={fpdClasses.select_field} name="Where_Do_You_Live"/>*/}
                    {/*</div>*/}
                    <div className={fpdClasses.question}>
                        <div className="w_100">
                            {/*<input type="tel"*/}
                            {/*       id="prefix"*/}
                            {/*       className={fpdClasses.inputFieldPrefix}*/}
                            {/*       disabled={true}*/}
                            {/*/>*/}

                            <PhoneInput
                                id={"phone_input"}
                                countryCallingCodeEditable={false}
                                placeholder="Enter phone number"
                                onChange={this.handleChange}/>
                            <input id="Phone_NUM"
                                   className={fpdClasses.inputFieldPhone}
                                   type="tel"
                                   name="Phone_NUM"
                                   onChange={onInputChange}
                                   onKeyUp={cleanPhone}
                                   placeholder="&nbsp;Your Best Phone Number"
                                   required
                                   onBlur={this.checkPhone}
                            />
                        </div>
                    </div>

                    <div className={fpdClasses.question}>
                        <input
                            className={fpdClasses.inputField}
                            type="email"
                            placeholder={"Email"}
                            onChange={onInputChange}
                            name="Email"
                            id={"Email"}/>
                    </div>
                    <div className={fpdClasses.question}>
                        <input
                            className={fpdClasses.inputField}
                            type="tel"
                            placeholder={"Age"}
                            name="Age"
                            onChange={onInputChange}
                            id={"Age"}/>
                    </div>
                    <div className={fpdClasses.question}>
                        <CountryListSimple name="Birth_Country"/>
                    </div>
                    <div className="w_100 middle center sp_w_100 inline padding_b">
                        <div className="w_80 margin_a">
                            <label><input className="input"
                                          onChange={onInputChange}
                                          id="AgreeInput"
                                          name="AgreeInput"
                                          type="checkbox"
                                          required
                                          aria-invalid="false"
                                // checked="true"
                            /><span style={{fontSize: ".8rem"}}>Yes, I agree to the <a
                                href={"/terms-of-service"} target="_blank">Terms of Service </a> and <a
                                href={"/privacy-policy"} target="_blank">Privacy Policy </a>and would like to receive my login details on Email.</span></label>
                        </div>
                    </div>
                    <div className="hide">
                        <input type="text" readOnly={true} name="Referrer_Link" id="Referrer_Link" value={utm_source}/>
                        <input type="text" readOnly={true} name="UTM_Source" id="UTM_Source" value={utm_source}/>
                        <input type="text" readOnly={true} name="FB_Campaign_Name" id="FB_Campaign_Name" value={utm_campaign}/>
                        <input type="text" readOnly={true} name="FB_Adset_Name" id="FB_Adset_Name" value={utm_term}/>
                    </div>
                    <div className="w_50 center sp_w_100 sp_padding_b inline">
                        <div className="w_85 margin_a">
                            <div
                                id="checkBtn"
                                disabled={true}
                                onClick={this.onSubmitForm}
                                className={fpdClasses.check_btn}>
                                <span className="ladda-label">Check now</span></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FormPersonalData;
