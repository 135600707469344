import StepsHeader from "../DifferenceComponents/StepsHeader/StepsHeader";
import SavingsForm from './RegistrationForm/SavingsForm/SavingsForm';
import MonthlyIncomeForm from './RegistrationForm/MonthlyIncomeForm/MonthlyIncomeForm';
import SimpleForm from "./RegistrationForm/SimpleForm/SimpleForm";
import CarouselForm from "./RegistrationForm/CarouselForm/CarouselForm";
import * as c from "../../state/variables";

document.title = document.title + " registration"

let RegTemplate = (props) => {
	if (props.template === 'MONTHLY_INCOME'){
		return (
			<div>
				<MonthlyIncomeForm state={props.state}/>
			</div>
		)
	}
	if(props.template === 'CAROUSEL_FORM'){
		return (
			<div>
				<CarouselForm state={props.state}/>
			</div>
		)
	}
	if(props.template === 'SIMPLE_FORM'){
		return (
			<div>
				<SimpleForm state={props.state}/>
			</div>
		)
	}
	if(props.template === 'SAVINGS'){
		return (
			<div>
				<SimpleForm state={props.state}/>
			</div>
		)
	}
	return (
		<div>
			<SimpleForm state={props.state}/>
		</div>
	)
}
// if(c.REGISTRATION_FORM_TEMPLATE === 'SAVINGS'){
// 	RegTemplate = (props) => {
// 		return(
// 			<div>
// 				<SimpleForm state = {props.state}/>
// 			</div>
// 	)
// 	}
// }
// if(c.REGISTRATION_FORM_TEMPLATE === 'CAROUSEL_FORM'){
// 	RegTemplate = (props) => {
// 		return(
// 			<div>
// 				<CarouselForm state = {props.state}/>
// 			</div>
// 		)
// 	}
// }
export default RegTemplate;
